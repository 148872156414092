<template>
  <div>
    <!-- 背景 -->
    <img src="@/image/nft_bg.jpg" class="bg" alt="" :style="'width:'+$setWidth">
    <!-- 头部 -->
    <div class="head flex_row" style="align-items: flex-start;">
      <!-- <img src="@/image/s_32.png" style="margin-top:30px;"> -->
      <div style="width: 35%;"></div>
      <div style="width: 0;flex: 1;">
        <div class="head_title">{{info.symbol}}</div>
        <div>{{$t('nft.mtg_price')}}：${{info.rate}}</div>
        <!-- <div>{{$t('nft.total_supply')}}：{{info.balance}}</div> -->
        <div>{{$t('nft.total_supply')}}：100</div>
        <div>{{$t('nft.sold')}}：{{ids}}</div>
        <div class="flex_lr">
          <div class="flex_row" style="width: 0;flex: 1;">
            <div>{{$t('nft.contract')}}：</div>
            <div id="copy" class="address">{{$setWidth=='100%'?address.slice(0,5)+'...'+address.slice(address.length-5):address.slice(0,10)+'...'+address.slice(address.length-10)}}</div>
          </div>
          <i class="ifont icone-fuzhi-11" @click="copy" style="color: #00FFEA;margin-left: 5px;"></i>
          
        </div>
      </div>
    </div>
    <!-- 搜索栏 -->
    <div class="search">
      <div class="search_box flex_lr">
        <i class="ifont icone-fangdajing1"></i>
        <input type="text" @keyup.enter="search" v-model="search_input" :placeholder="$t('nft.search_input')">
        <div class="search_btn hand" @click="search">{{$t('nft.search_btn')}}</div>
      </div>
    </div>
    <!-- 文字 -->
    <div class="y_center txt">Community list</div>
    <!-- 社区列表 -->
    <div class="flex_lr community">
      <div class="community_list flex_row hand" v-for="(item,index) in show_list" @click="toDetails(item.token)" :key="index">
        <div class="community_title"></div>
        <div class="community_text">{{get_language=='cn'?item.title:item.english_title}}</div>
      </div>
      <div style="width: 30%;"></div>
      <div style="width: 30%;"></div>
    </div>
    <div style="height: 30px;"></div>
  </div>
</template>

<script>
import walletHelper from "@/utils/walletHelper.js"
export default {
  name: "index",
  components: {},
  data() {
    return {
      data:{
        nft_price:0
      },
      info:{},
      address:'',
      list:[],
      show_list:[],
      current_page:1,
      last_page:0,
      ids:0,
      search_input:'',
    }
  },
  computed: {
		get_language() {
			return this.$store.state.user.language
		},
	},
  async mounted() {
    await this.$onLaunched;
    this.init();
  },
  created() {
    window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动事件
  },
  destroyed() {
    window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  },
  methods: {
    init() {

      this.get('/api/Community/index', {page:this.current_page}, 'POST').then( res => {
        this.last_page = res.list.last_page;//分页总数
        this.current_page = res.list.current_page;//当前页
        this.data = res
        if (this.current_page==1) {
          this.list = res.list.data
        } else {
          if(res.list.data.length>0){
            this.list = this.list.concat(res.list.data)
          }
        }
        this.show_list = this.list
      })
      this.address=walletHelper.getNFTcontract()
      this.showBlance()
    },
    showBlance(){
      const that=this
      try{
        walletHelper.getNFT().methods.symbol().call().then(( result)=>{
          that.$set(that.info,'symbol',result)
        })
      }catch(e){
        alert(e)
      }
      // walletHelper.getNFT().methods.totalSupply().call().then((result)=>{
      //   that.$set(that.info,'totalSupply',result)
      // })
      walletHelper.getNFT().methods.getTokenIds().call().then(res=>{
        that.ids=Number(res)
      })
      that.$set(that.info,'balance',500)
      // walletHelper.getNFT().methods.balanceOf(walletHelper.getAddress()).call().then((result)=>{
      //   that.$set(that.info,'balance',result)
      // })
      walletHelper.getBuy().methods.rate().call().then((result)=>{
        that.$set(that.info,'rate',walletHelper.fromWei(result))
      })
    },
    toDetails(token){
      if(this.info.symbol!=undefined){
        this.$store.commit('user/SET_INFO_NFT', this.info)
        this.$router.push('/nft_details/'+String(token))
      }
    },
    copy(){
      this.$copyText(this.address).then(e=>{
        this.$toast(this.$t('tips.title2'))
      }, e=> {
        this.$toast(this.$t('tips.title3'))
      })
    },
    next_pages(){
      if(this.list!=undefined&&this.list.length>0){
				this.current_page++
				if (this.last_page<this.current_page) {
					return
				}
				this.init()
			}
    },
    search(){
      var list = []
      if(this.get_language=='cn'){
        this.list.filter((p) => {
          if(p.title.toLowerCase().indexOf(this.search_input.toLowerCase()) !== -1){
            list.push(p)
          }
        })
      }else{
        this.list.filter((p) => {
          if(p.english_title.toLowerCase().indexOf(this.search_input.toLowerCase()) !== -1){
            list.push(p)
          }
        })
      }
      this.show_list = list
    },
    Scrollbottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("滚动到底部了")
        this.next_pages()
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .bg{
    position: fixed;
    z-index: -1;
    width: 100%;
    // height: 100%;
    left: 0;
    right: 0;
    margin: auto;
  }
  .head{
    padding: 20px;
    font-family: PingFang SC;
    color: #00FFEA;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    img{
      width: 80px;
      margin-right: 25px;
    }
    .head_title{
      font-size: 30px;
      font-weight: 800;
      line-height: 60px;
    }
    .address{
      width: 0;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .search{
    padding: 0 5px;
    font-size: 14px;
    font-family: Adobe Heiti Std;
    color: #00FFEA;
    .search_box{
      width: 100%;
      height: 40px;
      background: linear-gradient(to right,rgba(19, 50, 183, 0),rgba(19, 50, 183, 0.6) 10% 90%,rgba(19, 50, 183, 0));
      border-radius: 10px;
      padding: 0 15px;
      i{
        color: #00FFEA;
        margin: 0 15px;
      }
      input{
        width: 0;
        flex: 1;
        color: #00FFEA;
        background: none;
      }
      input::-webkit-input-placeholder{
        color: #00FFEA;
      }
      .search_btn{
        border-left: 1px solid #00FFEA;
        padding: 0 15px;
        margin-left: 10px;
      }
    }
  }
  .community{
    padding: 0 30px;
    flex-wrap: wrap;
    .community_list{
      margin-top: 20px;
      // background: linear-gradient(0deg, #F3F3F3, #D9D9D9);
      background: rgba(19, 50, 183,0.6);
      color: #00FFEA;
      width: 30%;
      height: 60px;
      border-radius: 5px;
      padding: 0 5px;
      .community_title{
        color: #d8b000;
        // white-space: nowrap;
      }
      .community_text{
        width: 0;
        flex: 1;
        display:-webkit-box;
        -webkit-line-clamp:1;
        -webkit-box-orient:vertical;
        overflow:hidden;
      }
    }
  }
  .txt{
    font-size: 24px;
    font-family: CentSchbkCyrill BT;
    font-weight: bold;
    color: #00FFEA;
    margin-top: 10px;
  }
</style>